@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import './style.scss';
@import './plan.scss';
@import './checkout.scss';
@import './subscriptions.scss';
@import './timesheet.scss';
@import './login.scss';
@import './overwrite.scss';
@import './newClient.scss';
@import 'react-checkbox-tree/lib/react-checkbox-tree.css';
@import './addmatter.scss';
@import 'organization.scss'
fieldset, .css-1ku04m1-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper, .css-1hvt842-MuiInputBase-root-MuiOutlinedInput-root, .MuiInputBase-root {
    border-radius: 4px !important;
}
//  <div style={{ padding: "10px" }}>
//                 <div class="floting-control textarea">
//                     <textarea type="text" name="name" rows="2" class="floting-input" placeholder="none" required="" />
//                     <label for="name" class="floting-label">Name</label>
//                 </div>
//             </div> 
// .floting {
//     font-family: 'Roboto', sans-serif;
//     &-control {
//         position: relative;
//         width: 100%;
//         height: 40px;
//         font-family: 'Roboto', sans-serif;
//         &.textarea {
//             height: 60px;
//         }
//     }
//     &-label {
//         position: absolute;
//         font-size: 12px;
//         font-weight: 400;
//         line-height: inherit;
//         left: 10px;
//         top: 11px;
//         padding: 0;
//         color: #9e9e9e;
//         background: #fafafa;
//         transition: all 0.3s ease;
//         z-index: 1;
//         font-family: 'Roboto', sans-serif;
//         padding: 0 5px;
//     }
//     &-input {
//         position: absolute;
//         font-size: 12px;
//         font-weight: 400;
//         line-height: inherit;
//         top: 0;
//         left: 0;
//         z-index: 1;
//         width: 100%;
//         height: auto;
//         padding: 10px 14px;
//         border-radius: 4px;
//         border: 1px solid rgba(0, 0, 0, 0.23);
//         color: black;
//         background: #fafafa;
//         font-family: 'Roboto', sans-serif;
//         &:hover {
//             border-color: #616161;
//         }
//         &::placeholder {
//             opacity: 0;
//             visibility: hidden;
//             color: transparent;
//         }
//         &:focus {
//             outline: none;
//             border: 2px solid #2196f3;
//             &~.floting-label {
//                 top: -10px;
//                 left: 10px;
//                 z-index: 5;
//                 transition: all 0.2s ease-in-out;
//                 font-size: 10px;
//                 font-weight: 400;
//                 font-family: 'Roboto', sans-serif;
//                 padding: 0 5px;
//                 color: #2196f3;
//             }
//         }
//         &:not(:placeholder-shown).floting-input {
//             &:not(:focus) {
//                 &~.floting-label {
//                     top: -10px;
//                     left: 10px;
//                     z-index: 9;
//                     font-size: 10px;
//                     font-weight: 400;
//                     transition: all 0.2s ease-in-out;
//                 }
//             }
//         }
//     }
// }
// color variants
@import 'themes-vars.module.scss';
// third-party
// @import '~react-perfect-scrollbar/dist/css/styles.css';
// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}
// ==============================|| APEXCHART ||============================== //
.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}
body {
    a {
        color: #03639d
    }
    .MuiButton-root.MuiButton-containedPrimary {
        background: #03639d;
    }
    // .Mui-disabled{
    //     opacity: 0.7;
    //     color:#fff !important
    // }
}
// ==============================|| PERFECT SCROLLBAR ||============================== //
.scrollbar-container {
    .ps__rail-y {
        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}
p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}
// ==============================|| ANIMATION KEYFRAMES ||============================== //
@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}
@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}
@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500&amp;display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Roboto',sans-serif
}
body {
    font-weight: 500;
    font-size: 15px;
}
.topnav {
    background-color: #03639d;
    padding: 4px;
    font-size: 1rem;
}
.bg-theme {
    background-color: #f7f7f7;
}
.brand-logo {
    height: 4.2rem;
    image-rendering: -webkit-optimize-contrast;
}
div#collapsibleNavbar {
    font-size: 0.9rem;
}
.btn-tryitfree {
    background-color: #36a900 !important;
    color: #fff !important;
    font-weight: 600;
    transition: all 0.4s;
    padding: 10px 20px;
    display: inline-block;
}
.btn-tryitfree:hover {
    box-shadow: 2px 2px 5px #00000091;
    transform: scale(1.02);
}
.list-color {
    color: #333
}
.work-category-card {
    text-decoration: none;
    color: #333;
}
/* div#banner {
    background: linear-gradient(45deg, #03639d ,#057ec7  100%);
    color:#fff;
} */
div#banner {
    background-color: #f7f7f7;
}
.btn-requestDemo {
    background-color: #03639d !important;
    color: #fff !important;
    font-weight: 600;
    transition: all 0.4s;
    text-transform: uppercase;
}
#collapsibleNavbar .nav-link {
    text-transform: capitalize;
}
.btn-requestDemo:hover {
    box-shadow: 2px 2px 5px #00000091;
    transform: scale(1.02);
}
.banner-heading {
    font-size: 2.2rem;
}
.bg-color1 {
    background-color: #e6f7ff;
}
/* .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    box-shadow: 1px 2px 5px #00000024;
} */
.dropdown-item:focus,
.dropdown-item:hover {
    color: #ffffff !important;
    background-color: #03639d;
}
.authoring-container {
    background-color: #f9f9f9;
    border-radius: 11px;
}
.tab-btn.active {
    color: white !important;
}
.addin-container {
    background-color: #f9f9f9;
}
.dropdown-item {
    text-transform: capitalize;
    padding: 6px 10px;
}
.btn-dark-blue {
    color: #fff !important;
    background-color: rgb(24, 19, 53) !important;
}
.bg-theme2 {
    background-color: #03639d;
}
.bg-subscribe {
    background-color: #e7e7e7;
}
section {
    padding: 3.7rem 0px;
}
.heading {
    font-size: 1.8rem;
    font-weight: 700;
}
.heading-border {
    border: 1px solid #979797;
    border-radius: 6px;
}
a.card-body.work-category-card:hover {
    color: #121212;
}
/*billing-car start*/
.billing-container {
    background: #f5f6f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.8;
    font-family: 'Open Sans', sans-serif;
}
.comingsoon-text {
    margin-top: 95px;
    font-size: 45px;
}
.form-text-lable {
    font-size: 18px;
    font-weight: bold;
    color: #363636;
}
.billing-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.card-basic,
.card-premium,
.card-standard {
    margin: 0 2rem 1rem 0;
    padding: 0 0 0.5rem 0;
    width: 15rem;
    background: #fff;
    color: #444;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
    overflow: hidden;
    transition: all 0.1ms ease-in-out;
}
.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
    transform: scale(1.02);
}
.card-header-billing {
    height: 5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 1rem 0;
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}
.header-basic,
.btn-billing-basic {
    background: linear-gradient(135deg, rgb(0, 119, 238), #06c766);
}
.header-standard,
.btn-billing-standard {
    background: linear-gradient(135deg, #b202c9, #cf087c);
}
.header-premium,
.btn-billing-premium {
    background: linear-gradient(135deg, #eea300, #ee5700);
}
.card-body-billing {
    padding: 0.5rem 0;
}
.card-body-billing h2 {
    font-size: 2rem;
    font-weight: 700;
}
.card-element-container {
    color: #444;
    list-style: none;
}
.btn-billing {
    margin: 0.5rem 0;
    padding: 0.7rem 1rem;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.1ms ease-in-out;
}
.btn-billing:hover {
    transform: scale(0.95);
}
.btn-billing:active {
    transform: scale(1);
}
.card-element-hidden {
    display: none;
}
/*billing-car End*/
/* .work-category-card */
.work-category-card {
    width: 100%;
}
.owl-carousel.typeofwork .owl-item img {
    border-radius: 1rem;
}
.subheading {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0px;
}
.typeofwork .owl-prev {
    position: absolute;
    top: calc(50% - 60px);
    left: 0px;
    background: #fff !important;
    width: 40px;
    height: 60px;
}
.typeofwork .card-body {
    padding: 5px 0px;
}
.typeofwork .owl-next {
    position: absolute;
    top: calc(50% - 60px);
    right: 0px;
    background: #fff !important;
    width: 40px;
    height: 60px;
}
.rightnavbar a {
    font-size: 14px;
}
#videoPopup .modal-body {
    padding: 0px;
    position: relative;
}
#videoPopup .modal-body iframe {
    height: 70vh;
}
.zindex {
    z-index: 99999999999999999999;
}
#videoPopup button.btn-close {
    position: absolute;
    background: #fff;
    z-index: 7;
    right: -20px;
    opacity: 1;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    top: -20px;
}
.demoform {
    background: linear-gradient(45deg, #03639d, #033e62);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.blog-article,
.blog-article:hover,
.blog-article a,
.blog-article a:hover {
    text-decoration: none;
    color: #000;
}
.blog-article {
    margin-bottom: 45px;
    transition: 0.3s all;
    display: block;
    border-radius: 10px;
    overflow: hidden;
}
.blog-article img {
    transition: 0.3s all;
    height: 250px;
    width: 100%;
}
.blog-article .blog-title {
    font-weight: 600;
    font-size: 1.5rem;
    text-transform: capitalize;
}
.blog-article:hover {
    box-shadow: 1px 3px 6px rgba(0, 0, 0, .1);
    transform: translateY(-10px);
}
.blog-article p {
    font-size: 15px;
}
.blog-content {
    padding: 20px 10px;
}
.product-banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
.p-relative {
    position: relative;
}
.z-index-3 {
    z-index: 3;
}
.overlay {
    background: rgba(9, 99, 157, 0.6);
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
footer {
    background: linear-gradient(45deg, #03639d, #033e62);
    color: #fff;
}
/* vertical tabs start*/
.nav-pills-custom .nav-link {
    color: #000;
    position: relative;
    background: #fff;
    border: 1px solid #efefef;
}
.nav-pills-custom .nav-link:hover {
    color: #ffffff;
    background: #03639d;
}
.nav-pills-custom .nav-link.active {
    color: #ffffff;
    background: #03639d;
    font-size: 600;
}
.owlblog .card-body {
    padding: 10px 0px;
}
.owlblog .owl-dots {
    text-align: center;
}
.owlblog .owl-dots .owl-dot span {
    width: 15px;
    border-radius: 10px;
    height: 10px;
    background-color: #ddd;
    display: inline-block;
}
.owlblog .owl-dots .owl-dot.active span {
    width: 40px;
    background-color: #03639d;
}
.owlblog .owl-dots .owl-dot {
    margin-right: 5px;
}
/* Add indicator arrow for the active tab */
.material-icons {
    font-size: 20px;
    vertical-align: middle;
}
@media (min-width: 992px) {
    .nav-pills-custom .nav-link::before {
        content: '';
        display: block;
        border-top: 8px solid transparent;
        border-left: 10px solid #03639d;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        opacity: 0;
    }
}
.nav-pills-custom .nav-link.active::before {
    opacity: 1;
}
.video-box {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding: 25px;
}
.video-box:after {
    content: '';
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
}
.play-btn {
    width: 100px;
    height: 100px;
    background: radial-gradient(rgb(3 99 157 / 75%) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    display: block;
    box-shadow: 0px 0px 25px 3px rgb(3 99 157 / 80%);
}
.product-wrapper section:nth-child(even) .product-row div:nth-child(2) {
    order: 1;
}
.product-wrapper section:nth-child(even) .product-row div:nth-child(1) {
    order: 2;
}
.bg-theme3 {
    background-color: #03639d;
}
/* triangle */
.play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
/* pulse wave */
.play-btn:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .75);
    top: -25%;
    left: -25%;
    transform: translate(-30%, -30%);
    background: rgba(198, 16, 0, 0);
}
@-webkit-keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}
@keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}
/* vertical tabs end*/
/*dashboard css style*/
#wrapper-dash {
    margin-left: 250px;
    transition: all .3s ease-in-out;
}
#wrapper-dash.fullwidth {
    margin-left: 0;
}
.btn-dash-edit {
    background-color: #03639d !important;
    color: #fff !important;
}
.btn-dash-edit:hover {
    background-color: #005487 !important;
    color: #fff !important;
}
.dashboard-right-section {
    background-color: whitesmoke;
}
/** --------------------------------
 -- Sidebar
-------------------------------- */
.sidebar {
    background-color: #ffffff !important;
    width: 250px;
    transition: all .3s ease-in-out;
    transform: translateX(0);
    z-index: 9999999
}
.sidebar .close-aside {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    color: #EEE;
}
.sidebar .sidebar-header {
    border-bottom: 1px solid #2a2b3c;
}
.sidebar .sidebar-header h5 a {
    color: #939393;
}
.sidebar .sidebar-header p {
    color: #3c3c3c;
    font-size: .825rem;
}
.sidebar .search .form-control~i {
    color: #2b2f3a;
    right: 40px;
    top: 22px;
}
.sidebar>ul>li {
    padding: .7rem 1.75rem;
}
.sidebar ul>li>a {
    color: #3c3c3c;
    text-decoration: none;
}
/* Start numbers */
.sidebar ul>li>a>.num {
    line-height: 0;
    border-radius: 3px;
    font-size: 14px;
    padding: 0px 5px
}
.sidebar ul>li>i {
    font-size: 18px;
    margin-right: .7rem;
    color: #6B7280;
}
.sidebar ul>li.has-dropdown>a:after {
    content: '\eb3a';
    font-family: unicons-line;
    font-size: 1rem;
    line-height: 1.8;
    float: right;
    color: #6B7280;
    transition: all .3s ease-in-out;
}
.sidebar ul .opened>a:after {
    transform: rotate(-90deg);
}
/* Start dropdown menu */
.sidebar ul .sidebar-dropdown {
    padding-top: 10px;
    padding-left: 30px;
    display: none;
}
.sidebar ul .sidebar-dropdown.active {
    display: block;
}
.sidebar ul .sidebar-dropdown>li>a {
    font-size: .85rem;
    padding: .5rem 0;
    display: block;
}
/* End dropdown menu */
.show-sidebar {
    transform: translateX(-270px);
}
.navbar-dash .nav-link {
    color: #03639d !important;
}
@media (max-width: 767px) {
    .sidebar ul>li {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .sidebar .search {
        padding: 10px 0 10px 30px
    }
}
/** --------------------------------
 -- welcome
-------------------------------- */
.welcome {
    color: #939393;
}
.welcome p {
    color: #3c3c3c;
}
.main-color {
    color: #03639d;
}
/** --------------------------------
 -- Please don't do that in real-world projects!
 -- overwrite Bootstrap variables instead.
-------------------------------- */
@media (max-width: 767px) {
    .navbar>.container-fluid .navbar-brand {
        margin-left: 15px !important;
    }
    .navbar .navbar-nav>li>a {
        padding-left: 0 !important;
    }
    .navbar-nav {
        margin: 0 !important;
    }
    .navbar .navbar-collapse,
    .navbar .navbar-form {
        border: none !important;
    }
}
/*.navbar .navbar-nav>li>a {
	float: left !important;
}
.navbar .navbar-nav>li>a>span:not(.caret) {
	background-color: #e74c3c !important;
	border-radius: 50% !important;
	height: 25px !important;
	width: 25px !important;
	padding: 2px !important;
	font-size: 11px !important;
	position: relative !important;
	top: -10px !important;
	right: 5px !important
}
.dropdown-menu>li>a {
	padding-top: 5px !important;
	padding-right: 5px !important;
}
.navbar .navbar-nav>li>a>i {
	font-size: 18px !important;
}*/
thead.bg-thead {
    background: #03639d;
    color: #fff;
}
.dashboard-section .navbar a {
    color: #383838;
}
.navbar-expand-md .navbar-nav .nav-link {
    font-size: 1.1rem;
}
/* Start media query */
@media (max-width: 767px) {
    #wrapper-dash {
        margin: 0 !important
    }
    .statistics .box {
        margin-bottom: 25px !important;
    }
    .navbar .navbar-nav .open .dropdown-menu>li>a {
        color: #CCC !important
    }
    .navbar .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #FFF !important
    }
    .navbar .navbar-toggle {
        border: none !important;
        color: #EEE !important;
        font-size: 18px !important;
    }
    .navbar .navbar-toggle:focus,
    .navbar .navbar-toggle:hover {
        background-color: transparent !important
    }
}
::-webkit-scrollbar {
    background: transparent;
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background-color: #929292;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #5c5c5c;
}
/*dashboard css end*/
.owlblog .owl-item img {
    border-radius: 8px;
    height: 250px;
}
.wraptextcss p,
.wraptextcss span,
.wraptextcss a {
    font-family: 'Roboto',sans-serif;
    font-size: 14px !important;
}
.subscribe-group .input-group-text {
    background-color: #36a900;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-right: 25px;
    cursor: pointer;
}
.subscribe-group .form-control {
    padding: 15px 20px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.footer-ul {
    list-style: none;
    padding: 0;
}
.access-container {
    height: auto;
}
.carousel-heading {
    color: #03639d;
}
.dropdown-toggle::after {
    content: none !important;
}
/* .nav-item:hover i.nav-arrow {
    transform: rotate(180deg) !important;
   
}

i.nav-arrow{
    transition: all 0.2s;
    font-size: 16px;
}

.nav-text{
    font-weight: 500;
}

.nav-item:hover .nav-text{
    color: rgb(8 102 158)!important;
} */
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #03639d;
}
.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}
.tools-nav .nav-link {
    color: #000;
    border-radius: 0px;
    margin-right: 10px;
    font-weight: 500;
}
.tools-nav .nav-link.active {
    color: #03639d !important;
    border-bottom: 4px solid #03639d;
    background-color: transparent;
}
#logo-banner {
    height: 150px;
    image-rendering: -webkit-optimize-contrast;
}
.authoring-container img {
    border-radius: 8px;
    height: 381px;
    max-height: 400px;
    object-fit: fill;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: -webkit-fill-available;
    image-rendering: -webkit-optimize-contrast;
}
#panel-1 {
    display: none;
}
.kronicle-counter .box {
    padding: 50px;
}
.kronicle-counter .box h3 {
    font-weight: 800;
    font-size: 40px;
}
.kronicle-counter .box.border-x {
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
.bg-light-gr {
    background: linear-gradient(45deg, #f2f2f2, #f8f8f8);
}
.z-index-3 {
    z-index: 3;
}
/* ********   Icon style    ******* */
.footer-social-icons {
    display: flex;
}
.social-icon {
    height: 36px;
    width: 36px;
    color: #fff;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    font-size: 21px;
    transition: background-color 250ms, border 250ms, transform 250ms;
}
.facebook:hover .social-icon {
    transform: translateY(-4px);
    background-color: #3c5a96;
    border: 1px solid #3c5a96;
    color: #fff;
}
.twitter:hover .social-icon {
    background-color: #59adeb;
    border: 1px solid #59adeb;
    color: #fff;
    transform: translateY(-4px);
}
.youtube:hover .social-icon {
    background-color: #de3236;
    border: 1px solid #de3236;
    color: #fff;
    transform: translateY(-4px);
}
.instagram:hover .social-icon {
    background-color: #704f9d;
    border: 1px solid #704f9d;
    color: #fff;
    transform: translateY(-4px);
}
.linkedin:hover .social-icon {
    background-color: #0579b6;
    border: 1px solid #0579b6;
    color: #fff;
    transform: translateY(-4px);
}
.blogger:hover .social-icon {
    background-color: #ef561c;
    border: 1px solid #ef561c;
    color: #fff;
    transform: translateY(-4px);
}
.footer-social-icons a {
    text-decoration: none;
}
.footer-ul a {
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    padding-bottom: .5rem;
    display: block;
    transition: 0.4s all;
    font-size: 11pt;
}
footer h6 {
    font-weight: 600;
    text-transform: uppercase;
}
.footer-ul li:hover a {
    color: #fff;
    transform: translateX(5px);
    /* font-weight: 600; */
}
.carousel-heading {
    margin-top: 68px;
    font-weight: 500;
}
/* dilshad */
.blog-img-details {
    height: 350px;
    width: 100%;
}
.related-img {
    height: 180px;
    overflow: hidden;
}
.border-start {
    border-left: 7px solid #03639d !important;
}
.recent-card {
    transition: all 0.4s;
}
.recent-card:hover {
    transform: translateY(-2px);
    box-shadow: 3px 3px 3px #0000001c !important;
}
.recent-card a {
    text-decoration: none;
    color: black;
}
.tnc-container {
    /* width: ; */
}
/* **********  login css ********** */
.login-container {
    background: linear-gradient(45deg, #03639d, #033e62);
}
.login-form {
    height: 360px;
    width: 360px;
    background-color: white;
    box-shadow: 4px 4px 9px #00000059;
}
.prefix {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    left: 15px;
    z-index: 2;
    color: #999;
    font-size: 80%;
}
.form-containt .form-group .form-control {
    padding: 10px;
    padding-left: 44px;
    border-color: rgb(223, 225, 230);
    border-width: 2px;
    box-shadow: none;
    font-size: 13px;
}
.form-containt .form-group {
    position: relative;
}
.header-brand-name {
    color: #03639d;
}
.login-btn {
    background-color: #0170b6;
    color: #fff;
}
.login-btn:hover {
    background-color: #006aac;
    color: #fff;
}
/* **********  login css end********** */
.mob-icon {
    transition: all 0.3s;
}
.mob-no:hover .mob-icon {
    transform: rotate(30deg);
    margin-right: 2px;
    transition: all 0.3s;
}
#logo-banner {
    border-radius: 7px;
}
.dropdown:hover .dropdown-menu {
    margin-top: 0;
    animation-name: down-animate;
    animation-duration: 0.5s;
}
.form-bottom-link a {
    text-decoration: none;
    color: #464646;
}
.form-bottom-link a:hover {
    text-decoration: none;
    color: #000000;
}
a.dropdown-item {
    font-size: 14px;
    padding: 10px 16px;
}
@keyframes down-animate {
    from {
        transform: translateY(-10px);
    }
    to {
        transform: translateY(0px);
    }
}
.offcanvas.show {
    transform: none;
    border-radius: 0px 12px 12px 0px;
}
.navbar-toggler {
    color: rgb(255 255 255 / 0%) !important;
    border-color: rgba(0, 0, 0, .1);
}
.text-justify {
    text-align: justify;
}
.assess-tab-containt {
    padding: 0rem 6rem;
    text-align: center;
    /* font-size: 0.9rem; */
}
.assess-text-containt {
    font-size: 0.9rem;
}
/* .youtube-container{
    height: auto;
    background: linear-gradient(
45deg, #03639d, #033e62);
    border-radius: 8px;
} */
.clause-search {
    padding-left: 2.5rem;
}
.form-control:focus {
    box-shadow: none;
}
.left-right-icon {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    background-color: white;
    border-radius: 45px;
    height: 2.5rem !important;
    width: 2.5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 3px #3e3e3e98;
    transition: all .5s;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
    background: transparent !important;
    border: none;
}
.left-right-icon:hover {
    box-shadow: 1px 1px 4px #3e3e3ec7;
    transform: scale(1.05);
}
.contracting-Tools-text {
    background-color: #03639d;
    color: white;
    border: none;
}
.contracting-Tools-text:hover {
    background-color: #005488;
    color: white;
    border: none;
}
span.material-icons.product-icons {
    font-size: 3rem;
}
.product-container:hover {
    box-shadow: 2px 2px 5px #ababab;
}
/* dilshad */
/* tag section start */
.sidebar-search {
    background: linear-gradient(45deg, #03639d, #033e62);
}
.accordion-cbt {
    background-color: none !important;
    border: none !important;
    margin-bottom: 1rem !important;
    box-shadow: 2px 2px 5px darkgrey;
}
.accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}
.sidebar-accordion button.accordion-button.fw-bold.collapsed {
    background-color: whitesmoke;
}
.sidebar-accordion .accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: none !important;
}
.accordion-cbt-list,
.accordion-trends-list {
    list-style: none !important;
    padding: 0;
}
.accordion-cbt-list li {
    margin-bottom: .9rem;
    font-size: .95rem;
}
.accordion-cbt-list li:hover {
    text-decoration: underline !important;
}
.accordion-cbt-list li>a {
    text-decoration: none;
    color: #0675ac;
}
.accordion-trends-list li {
    margin-bottom: .7rem;
    font-size: .9rem;
}
.accordion-trends-list li:hover {
    text-decoration: underline !important;
}
.accordion-trends-list li>a {
    color: #0675ac;
    text-decoration: none;
}
.sidebar-search #search-contract-type {
    height: 2.2rem;
}
.all-tags a {
    text-decoration: none;
    color: #0675ac;
    cursor: pointer;
    font-size: .9rem;
}
.all-tags a:hover {
    text-decoration: underline;
}
.all-tags a:hover span.rounded-pill.bg-light {
    text-decoration: none !important;
}
.all-tags span.rounded-pill.bg-light {
    color: #747474;
    font-size: .9rem;
    font-weight: bold;
}
.search-sidebar {
    background: linear-gradient(272deg, #017fcb, #033e62) !important;
    color: #fff !important;
}
.sidebar-accordion .search-sidebar:not(.collapsed) {
    color: #fff !important;
    box-shadow: none !important;
}
.search-sidebar:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
    filter: brightness(100) !important;
}
.search-sidebar::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    filter: brightness(100) !important;
}
.search-btn {
    color: #fff;
    background-color: #03639d;
    border-color: #03639d;
}
.search-btn:hover {
    color: #fff;
    background-color: #005285;
    border-color: #005285;
}
/* tag section End */
.card-heading-color {
    color: #03639d;
}
.sample-btn {
    box-shadow: 2px 2px 5px rgb(104, 104, 104) !important;
}
.btn-radius {
    border-radius: 5px;
}
.text-color {
    color: #03639d;
}
.text-color:hover {
    color: #03639d;
    text-decoration: underline;
}
.subscribe-txt {
    text-align: center !important;
    color: #646464;
}
.Step-button-dot {
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #707070;
    border-radius: 45px;
    border: 2px solid #707070;
}
.dot-selected {
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #707070;
    color: white;
    border-radius: 45px;
    box-shadow: 2px 1px 10px #a1a1a1;
}
/* .step-button-text::after {
    background-color: #afafaf;
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 0 1rem;
    width: 2.375rem;
} */
.step-button-text {
    align-items: center;
    display: flex;
    text-align: left;
}
.green-dot {
    background-color: rgb(0, 170, 0);
    border: 2px solid rgb(0, 170, 0);
    color: #fff;
    box-shadow: 2px 1px 10px #a1a1a1;
}
.step-display {
    display: flex;
}
img.correct-icon {
    height: 15px;
    margin-right: .5rem;
}
.package-list {
    list-style: none;
    padding-left: 1rem;
    text-align: start;
}
.pricingTable {
    margin-top: 30px;
    text-align: center;
    position: relative;
}
.pricingTable .pricing_heading:after {
    content: "";
    width: 36px;
    height: 100%;
    background: #03639d;
    position: absolute;
    top: -1px;
    right: 0;
    z-index: 2;
    transform: skewY(45deg) translateY(18px);
    transition: all 0.4s ease 0s;
}
.pricingTable .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #fff;
    text-transform: uppercase;
    background: #03639d;
    padding: 15px 0 0;
    margin: 0 35px 0 0;
    transition: all 0.4s ease 0s;
}
.pricing_heading {
    position: relative;
}
.pricingTable .value {
    display: block;
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    background: #03639d;
    padding: 5px 0 10px;
    margin: 0 35px 0 0;
    transition: all 0.4s ease 0s;
}
.pricingTable:hover .title,
.pricingTable:hover .value {
    color: #fff;
}
.pricingTable .month {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}
.pricingTable .content {
    border-left: 1px solid #f2f2f2;
    position: relative;
}
.pricingTable .content:after {
    content: "";
    width: 35px;
    height: 100%;
    background: #f8f8f8;
    box-shadow: 9px 9px 20px #ddd;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform: skewY(-45deg) translateY(-18px);
    transition: all 0.4s ease 0s;
}
.pricingTable .content ul {
    padding: 0;
    margin: 0 35px 0 0;
    list-style: none;
    background: #fff;
}
.pricingTable .content ul li {
    display: block;
    font-size: 15px;
    color: #333;
    line-height: 23px;
    padding: 11px 0;
    border-bottom: 1px solid #f2f2f2;
}
.pricingTable .link {
    background: #fff;
    padding: 20px 0;
    margin-right: 35px;
    border-bottom: 1px solid #f2f2f2;
}
.pricingTable .link a {
    display: inline-block;
    padding: 9px 20px;
    font-weight: 700;
    color: #03639d;
    text-transform: uppercase;
    border: 1px solid #03639d;
    background: #fff;
    transition: all 0.4s ease 0s;
}
.pricingTable:hover .link a {
    color: #fff;
    background: #06D6A0;
    border: 1px solid #06D6A0;
}
.pricingTable:hover .pricing_heading:after,
.pricingTable:hover .title,
.pricingTable:hover .value {
    background: #06D6A0;
}
.topnav p {
    margin: 0px;
    padding: 0px;
}
.bg-theme4 {
    background: #f1f1f1;
    color: #666 !important;
}
.uil-bars,
i.uil-bars.close-aside {
    font-size: 25px;
    color: #626262;
}
.page-link {
    color: #034b77 !important;
}
.bg-theme4.active {
    background-color: #03639d !important;
    color: #fff !important;
}
.navbar-header-dash {
    display: flex;
    align-items: center;
}
.vh-80 {
    min-height: 85vh !important;
}
.imgw-100 {
    width: 100%;
    height: 360px;
}
@media only screen and (max-width: 990px) {
    .pricingTable {
        margin-bottom: 35px;
    }
}
@media only screen and (min-width: 992px) {
    .offcanvas {
        display: none !important;
    }
    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        box-shadow: 1px 2px 5px #00000024;
        animation-name: down-animate;
        animation-duration: 0.5s;
    }
    .step-button-text::after {
        background-color: #afafaf;
        border-radius: 3px;
        content: '';
        display: block;
        height: 3px;
        margin: 0 1rem;
        width: 2.375rem;
    }
    .nav-item:hover i.nav-arrow {
        transform: rotate(180deg) !important;
        /* transition: all 0.4s; */
    }
    i.nav-arrow {
        transition: all 0.2s;
        font-size: 16px;
    }
    .nav-text {
        font-weight: 500;
    }
    .nav-item:hover .nav-text {
        color: rgb(8 102 158) !important;
    }
    .popular-post {
        position: sticky !important;
        position: "-webkit-sticky" !important;
        top: 92px;
    }
    /* .dropdown-menu:before {
        content: "";
        position: absolute;
        top: -7px;
        background-color: #fff;
        width: 15px;
        height: 15px;
        -webkit-clip-path: polygon(0 0,100% 100%,0 100%);
        clip-path: polygon(0 0,100% 100%,0 100%);
        transform: rotate(135deg);
    } */
}
@media only screen and (max-width: 993px) {
    a.btn.btn-tryitfree,
    a.btn.btn-requestDemo {
        font-size: .9rem;
    }
    ul.list-unstyled {
        font-size: .9rem;
    }
    li.nav-item.dropdown a {
        font-size: .9rem;
        padding: .7rem;
        /* border-bottom: 1px solid #d5d5d5; */
    }
    a.nav-link.show {
        color: #03639d !important;
    }
    a.nav-link.show>i.nav-arrow {
        transform: rotate(180deg) !important;
        /* transition: all 0.4s; */
    }
    .navbar-nav .dropdown-menu {
        position: static;
        border: none;
        margin-left: 1rem;
    }
    .social-icon-canvas {
        height: 36px;
        width: 36px;
        color: #6c6c6c;
        border-radius: 50%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border: 1px solid #6c6c6c;
        font-size: 21px;
        transition: background-color 250ms, border 250ms, transform 250ms;
    }
}
@media only screen and (min-width: 768px) {
    .hide-in-desktop {
        display: none !important;
    }
}
@media only screen and (max-width: 1200px) {
    .navbar-nav li a {
        font-size: .8rem;
    }
    .offcanvas-body .navbar-nav li a {
        font-size: 17px;
        padding-left: 14px;
    }
    .banner-heading {
        font-size: 27px;
    }
    .heading {
        font-size: 25px;
        font-weight: 600;
    }
    p.bottom-heading-sm {
        font-size: 12px !important;
        padding: 0px 25px;
    }
}
@media only screen and (max-width: 768px) {
    .hide-in-mobile {
        display: none !important;
    }
    .heading {
        font-size: 18px !important;
        font-weight: 800;
    }
    .banner-heading {
        font-size: 24px;
    }
    .banner-img {
        height: 250px;
    }
    .banner-subheading {
        font-size: 18px;
    }
    .middle-in-mobile {
        display: flex;
        justify-content: center;
    }
    section {
        padding: 20px 0px;
    }
    .banner-text {
        padding-top: 30px;
    }
    .topnav {
        background-color: #03639d;
        padding: 0px;
        font-size: 10px;
    }
    .topnav span.h6.fw-bold {
        font-size: 9px;
    }
    .subscribe-txt {
        font-size: 11px;
        text-align: justify !important;
        color: #646464;
    }
    .offcanvas-start {
        top: 0;
        left: 0;
        width: 320px;
        border-right: 1px solid rgba(0, 0, 0, .2);
    }
    #videoPopup .modal-body iframe {
        height: 30vh;
    }
    .modal.show .modal-dialog {
        transform: none;
        margin-top: 100px;
        padding: 11px;
    }
    .step-display {
        display: block;
    }
    .step-button-text {
        align-items: center;
        display: flex;
        text-align: left;
        font-size: .8rem;
        padding-top: .5rem;
    }
}
@media only screen and (max-width: 450px) {
    .offcanvas-start {
        top: 0;
        left: 0;
        width: 280px;
        border-right: 1px solid rgba(0, 0, 0, .2);
    }
}
.aftlog {
    display: none;
}
@media(max-width:769px) {
    .product-wrapper section .product-row div:nth-child(2) {
        order: 1;
    }
    .product-wrapper section .product-row div:nth-child(1) {
        order: 2;
    }
}
.aftlog {
    display: block;
}
.beflog {
    display: none;
}
#toggle-navbar {
    display: none !important;
}
.btn-search {
    background-color: #03639d !important;
    color: #fff !important;
    font-weight: 600;
    transition: all 0.4s;
    padding: 10px 20px;
    display: inline-block;
}
.btn-search:hover {
    box-shadow: 2px 2px 5px #00000091;
    transform: scale(1.02);
}
.switch_item {
    display: flex;
    justify-content: flex-start;
}
.rightnavbar {}
table {
    tr {}
}
.simplebar-placeholder {
    display: none
}
.MuiTableCell-root {
    padding-left: 15px;
}
.MuiOutlinedInput-notchedOutline {
    legend {
        float: left
    }
}
.invoice_main {
    .invoice_card {
        padding: 25px
    }
    .sub_table_box {
        display: flex;
        justify-content: flex-end;
        table {
            max-width: 60%;
        }
    }
    .MuiTableCell-head.MuiTableCell-root {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #afaaaa;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 0;
    }
    .MuiTableCell-root {
        border-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        h5 {
            color: #03639d
        }
    }
    .MuiTableCell-root.title {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #afaaaa;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 0;
        width: 30%;
    }
    .customer_details {
        margin-top: 25px;
        h6 {
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #afaaaa;
            font-weight: 400;
            font-size: 14px;
        }
        .head {
            .cus_name {
                color: #03639d;
                font-size: 24px;
                font-weight: 600;
            }
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
}
.con-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item {
        width: 30%;
        background: #fff;
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, .05);
        border-radius: 20px;
        margin: 0px;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: all .25s ease;
        position: relative;
        margin: 15px;
    }
    .item:not(.color):hover {
        transform: scale(1.05);
    }
    .item:hover .con-img {
        transform: scale(1.15);
    }
    .item.color:hover {
        transform: scale(1.15);
    }
    .con-img {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .25s ease;
    }
    .con-img img {
        width: 130px;
    }
    .item1 {
        padding-right: 45px;
    }
    .item2 {
        z-index: 100;
    }
    .item3 {
        padding-left: 45px;
    }
    .item.color {
        background: #3dcbab;
        color: #fff;
        transform: scale(1.1);
    }
    .item.color li {
        color: rgba(255, 255, 255, .75);
    }
    .item.color li b {
        color: rgba(255, 255, 255, 1);
    }
    .item.color li i {
        color: rgba(255, 255, 255, 1);
    }
    .item header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        width: 100%;
    }
    .item header h3 {
        font-size: 16px;
        font-weight: 500;
        color: #03639d
    }
    .item header p {
        font-size: 1.2rem;
        b {
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #afaaaa;
            font-weight: 400;
            font-size: 14px;
            padding-bottom: 0;
        }
    }
    .badge {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #fff;
        padding: 5px 10px;
        border-radius: 12px;
        color: #03639d;
        font-weight: bold;
        font-size: .85rem;
    }
    .item ul {
        padding: 20px 0px;
        flex: 1;
        width: 100%;
    }
    .item ul li {
        width: 100%;
        padding: 2px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        color: rgb(var(--color), .5)
    }
    .item ul li b {
        color: rgb(var(--color), 1)
    }
    .item ul li i {
        font-size: 1.6rem;
        margin-right: 15px;
        color: rgb(var(--color), 1)
    }
    .item button {
        padding: 6px 20px;
        width: 100%;
        background: #03639d;
        border: 2px solid transparent;
        border-radius: 6px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        transition: all .25s ease;
    }
    .item button:not(.border):hover {
        transform: translate(0, 5px);
        box-shadow: 0px 0px 0px 0px rgba(3, 99, 157, .35);
    }
    .item button.border {
        border: 3px solid #fff;
    }
    .item button.border:hover {
        background: #fff;
        color: #03639d;
    }
}
.my_org_main {
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .MuiPaper-root {
        padding: 25px
    }
    .org_table {
        .MuiTableCell-root {
            border-bottom: 0;
            letter-spacing: 1px;
            color: #afaaaa;
            font-weight: 400;
            font-size: 18px;
            padding-bottom: 10px;
            b {
                color: #03639d
            }
        }
    }
}
.duration_text {
    letter-spacing: 1px;
    color: #afaaaa;
    font-weight: 400;
    font-size: 14px;
}
.plans_main {
    .most {
        background-color: #03639d;
        .MuiCardHeader-title {
            color: #fff;
        }
        svg {
            fill: white;
        }
        .MuiCardHeader-subheader {
            letter-spacing: 1px;
            color: #afaaaa;
            font-weight: 400;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}
.select_plan_type {
    .MuiFormGroup-root {
        flex-direction: row;
    }
    .MuiFormLabel-root {
        margin-bottom: 20px;
        letter-spacing: 1px;
        color: #afaaaa;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
    }
    .MuiFormControlLabel-root {
        background-color: #ccc;
        padding: 20px 30px;
        margin: 0 15px;
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    }
}
.assign_table {
    tr {
        th {
            text-align: center;
        }
        td {
            text-align: center;
            padding: 15px;
            width: 20%;
            &:last-child {
                width: 15%
            }
        }
    }
}
.product_assign_org_table {
    .org_table {
        table {
            tr {
                td.sm_wd {
                    width: 30%
                }
            }
        }
    }
}
.date_time_picker {
    width: 100%;
}
.timesheet_main {
    display: flex;
    .timesheet_center {
        flex: 1;
    }
    .timesheet_calendar {
        width: 22%;
        padding: 0 15px;
        .entry_card {
            padding: 5px;
            table {
                tr {
                    td {
                        font-size: 12px;
                        padding: 5px 3px;
                    }
                }
            }
        }
    }
}
.current_week_box {
    margin-top: 10px;
    .MuiBox-root {
        padding: 0;
    }
    .tab_head_box {
        background: #f1f1f1;
        .MuiBox-root {
            display: flex;
            justify-content: center;
        }
    }
    .MuiTabs-centered {
        background: #f1f1f1;
        margin-top: 10px;
        .MuiTab-root.Mui-selected {
            color: #2196f3
        }
        .MuiTab-root {
            color: #333
        }
    }
}
.table_time {
    width: 100%;
    margin-top: 15px;
    th {
        text-align: center;
    }
    tr {
        td {
            padding: 10px 2.5px;
            text-align: center;
        }
    }
}
.time_picker_box {
    .MuiTextField-root {
        width: 120px;
    }
    .MuiIconButton-root {
        padding: 0;
    }
}

.records {
    list-style: none;
    margin: 0;
    padding: 15px 25px 10px;
    display: flex;
    width: 100%;
    background: #f7f7f7;
    li {
        padding: 0 7px;
        border-right: 1px solid #ddd;
        &:last-child {
            border-right: none;
        }
    }
}
.close_modal {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99999;
}
.table_body {
    max-height: 400px;
    overflow-y: auto;
}
.clause_extra_table {
    max-height: 350px;
    overflow-y: auto;
    .MuiTableCell-root {
        padding: 15px 10px;
    }
}
// template-filter css
.template {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    &_top {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .customization {
            padding: 20px;
            width: 100%;
            justify-content: center;
            display: flex;
            gap: 20px;
            width: 60%;
            &_prompt {
                width: 100%;
                label, input, select {
                    width: 100%;
                    display: block;
                }
            }
        }
        .prompt_right {
            margin: auto;
            width: 40%;
            padding: 20px;
            &_wrp {
                min-height: 40px;
                max-height: 55px;
                width: 100%;
                margin: auto;
                border: 1px solid #c0c0c0;
                overflow: auto;
                margin-bottom: 10px;
                height: 100%;
                padding: 10px;
                border-radius: 10px;
                margin-top: 4px;
                p {
                    margin: 0;
                }
            }
            >div {
                button {
                    max-width: 200px;
                    margin: auto;
                }
            }
        }
    }
    &_bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
        >div {
            text-align: center;
            width: 35%;
            textarea {
                height: 300px;
                width: 100%;
                display: block;
                margin: 10px auto;
            }
        }
    }
    &_option {
        width: 150px !important;
    }
    &_ner {
        width: 25% !important;
        min-width: 200px;
    }
}
// apitesting
.apitesting {
    &_content {
        input {
            height: 400px;
        }
        textarea {
            height: 425px;
        }
        &_right {
            &_wrp {
                >div {
                    min-height: 410px;
                    max-height: 425px;
                    overflow: auto;
                    border-color: rgb(118, 118, 118) !important;
                }
            }
        }
    }
    .MuiButton-root.MuiButton-containedPrimary {
        &.Mui-disabled {
            color: #fff !important;
        }
    }
}
.calender_sync {
    .MuiOutlinedInput-notchedOutline legend {
        float: unset;
    }
}
//zohologin
.zohologin {
    a {
        background: #03639d;
        min-width: 135px;
        height: 100%;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        font-size: 0.875rem;
        line-height: 24px;
        color: #fff;
        padding: 6px 16px;
        text-decoration: none;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        font-weight: 500;
        border-radius: 4px;
        &:hover {
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        }
    }
}
.MuiOutlinedInput-notchedOutline legend {
    float: unset;
}
//case Search
.casesearch {
    display: flex;
    gap: 20px;
    height: calc(100% - 80px);
    overflow: auto;
    &_left {
        width: 100%;
        .search_filters {
            display: flex;
            align-items: flex-start;
            margin: 0;
            &_wrp {
                max-height: 610px;
                overflow: auto;
                padding-top: 10px;
                padding-right: 4px;
                input, fieldset {
                    border-radius: 4px;
                }
            }
            &_btn {
                padding-top: 10px;
            }
        }
    }
    &_right {
        width: 100%;
    }
}
.truncate-text {
    min-width: 200px;
    display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
}
// / case_details /
.case_details {
    width: 100%;
    &_left {
        width: 65%;
    }
    &_right {
        width: 35%;
    }
}
.legislation {
    .css-14qh0u1-MuiOutlinedInput-notchedOutline *, .css-14qh0u1-MuiOutlinedInput-notchedOutline {
        border-radius: 4px;
    }
}
.correction {
    .MuiOutlinedInput-notchedOutline legend {
        float: left;
    }
}
.judgmentdate {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
//// plan warning modal design 
.plan_warning {
    h4 {
        text-align: center;
        margin-bottom: 30px;
    }
    &_table {
        margin-top: 10px;
        width: 100%;
        overflow: auto;
        table {
            width: 100%;
            margin-bottom: 40px;
            th, td, tr {
                border-width: 2px;
                padding: 5px;
            }
        }
    }
}
//template settings screen 
.template_settings {
    .customization {
        align-items: flex-end;
        padding: 0 15px;
        margin-bottom: 15px;
    }
}
// product > add product screen
.product_image {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    gap: 10px;
    li {
        width: 100px;
        height: 100px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
            object-fit: contain;
        }
        input {
            display: none;
            &:checked+label {
                border-color: #03639d;
            }
        }
        label {
            border: 1px solid #e0e0e0;
            padding: 4px;
            cursor: pointer;
            border-radius: 3px;
            width: 100%;
            height: 100%;
        }
    }
}
.image_upload {
    text-align: center;
    &_img {
        width: 140px;
        height: 140px;
        padding: 10px;
        border: 1px solid #ccc;
        margin: auto;
        margin-top: 5px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .close_modal {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99999;
        top: -10px;
        right: -13px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: lightgray;
    }
}
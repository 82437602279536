.addmatter {
    &_wrp {
        padding-top: 60px;
        &_left {
            width: calc(100% - 190px);
            max-width: 750px;
        }
        &_right {
            width: 190px;
            padding-left: 20px;
            text-align: right;
            button 
            {
                position: sticky;
                top: 60px;
                width: 140px;
            }
        }
    }
}
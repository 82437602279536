.gutter {
    min-width: 10px;
    cursor: col-resize;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
    z-index: 1;
    display: block;
    // background-color: #18639d;
    background-repeat: no-repeat;
    // background-position: 50%;
    background-position: top;
}
.billable_nb {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 4px;
    li {
        border-right: 1px solid #00000033;
        padding: 0 8px;
        &:last-child {
            border-right: none;
        }
        .b {
            color: green;
        }
        .nb {
            color: red
        }
    }
}
.timesheet_main {
    display: flex;
    .timesheet_center {
        flex: 1;
    }
    .timesheet_calendar {
        width: 22%;
        padding: 0 15px;
        .entry_card {
            padding: 5px;
            table {
                tr {
                    td {
                        font-size: 12px;
                        padding: 5px 3px;
                    }
                }
            }
        }
    }
}
.timesheet_main {
    &_wrp {
        &_header {
            &_left {
                &_week_box {
                    display: inline-block;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    border: 1px solid #00000033;
                    .MuiBox-root {
                        padding: 0;
                    }
                    .tab_head_box {
                        background: #fff;
                        .MuiBox-root {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-bottom: 0;
                        }
                    }
                    button.MuiButtonBase-root {
                        width: 46px;
                        min-width: 46px;
                        min-height: 46px;
                        max-width: 46px;
                        border-radius: 4px;
                        margin: 0 5px 0 0;
                    }
                    .MuiTabs-centered {
                        background: #fff;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        font-weight: 500;
                        .MuiTab-root.Mui-selected {
                            color: #005287;
                            background-color: #E3F5FD;
                            border: none;
                            font-weight: bold;
                        }
                        .MuiTab-root {
                            color: #333
                        }
                    }
                    .MuiTabs-indicator {
                        display: none;
                    }
                }
            }
        }
    }
    &_new {
        display: flex;
        height: calc(100vh - 211px);
        overflow: hidden;
        .timesheet_center {
            flex: 1;
            &_wrp {
                padding: 0;
                background-color: #ffffff;
                color: #616161;
                border-radius: 4px;
                box-shadow: none;
                background-image: none;
                border-radius: 4px;
                overflow: hidden;
                .current_week_box {
                    margin: 0;
                    .total_time {
                        width: 90px;
                        h5 {
                            font-size: 12px;
                            color: #404040;
                            font-weight: 500;
                            margin-bottom: 5px;
                        }
                        label {
                            font-size: 12px;
                            width: 25px;
                            user-select: none;
                        }
                        .counter {
                            margin-left: 2px;
                            position: relative;
                            padding: 0 5px;
                            border: 1px solid;
                            border-radius: 20px;
                            background-color: #fff;
                            padding: 0 15px;
                            user-select: none;
                            input {
                                text-align: center;
                                border: none;
                                padding: 0 2px;
                                &::-webkit-outer-spin-button,
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                                &:focus-visible {
                                    outline: none;
                                }
                            }
                            span {
                                position: absolute;
                                cursor: pointer;
                                &:first-child {
                                    left: 0;
                                }
                                &:last-child {
                                    right: 0;
                                }
                            }
                        }
                    }
                    .table_heading {
                        padding: 10px;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        border: 1px solid #C2C2C2;
                        h4 {
                            color: #000;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 24px;
                            margin-bottom: 0;
                        }
                    }
                }
                .MuiTableCell-root {
                    padding-left: unset;
                    padding: 10px;
                }
            }
        }
        .timesheet_calendar {
            width: 22%;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #C2C2C2;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            .table_heading {
                background-color: #fff;
                padding: 6px 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: 1px solid #C2C2C2;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
                h4 {
                    color: #000;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    margin-bottom: 0;
                }
                .push_entries {
                    width: 105px;
                    border: 1px solid #005287;
                    background-color: #E3F5FD;
                    color: #005287;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 5px 10px;
                    text-align: center;
                    border-radius: 4px;
                    &:disabled {
                        opacity: 0.5;
                    }
                }
            }
            .entry_card {
                overflow: auto;
                height: 100%;
                background-color: #F2F2F7;
                border-radius: 5px;
                min-height: 300px;
                padding-bottom: 110px;
                overflow: hidden;
                table {
                    padding: 0 5px;
                    tr {
                        td {
                            font-size: 12px;
                            padding: 5px 3px;
                        }
                    }
                }
            }
            .bottom_filter {
                position: absolute;
                bottom: 0;
                padding: 8px 16px;
                box-shadow: 0px -2px 8px 0px #0000001F;
                background-color: #fff;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                width: 100%;
                left: 0;
                right: 0;
                margin: auto;
            }
            .table_time {
                margin-top: 0;
            }
        }
    }
}